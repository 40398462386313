import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ChangelogTab from './components/ChangelogTab/ChangelogTab';
import 'react-tabs/style/react-tabs.css';
import './updates.scss';

class Updates extends Component {
  state = {
    selectedTab: 'changelog'
  }

  selectTab = (tab) => {
    this.setState({ ...this.state, selectedTab: tab });
  }

  getCurrentTab = () => {
    switch (this.state.selectedTab) {
      case 'changelog':
        return <ChangelogTab />
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="updates">
        <div className="row">
          <div className="tabordion" tabIndex="-1">
            <section id="updates-section" tabIndex="0">
              <div onClick={() => this.selectTab('changelog')} tabIndex="0" className={this.state.selectedTab === 'changelog' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.changelog'} defaultMessage="Changelog" />
              </div>
            </section>
            <div className="tab-content" tabIndex="0">
              {this.getCurrentTab()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Updates;

