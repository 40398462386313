import React from "react";
import PropTypes from 'prop-types';

import './group.scss';

const Group = ({ name }) => (
  <div className="group">{name}</div>
);

Group.propTypes = {
  name: PropTypes.string,
};

export default Group;
