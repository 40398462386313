import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class ChangelogTab extends React.Component {

  render() {
    return (
    <p><FormattedHTMLMessage id="wiki.updates" defaultMessage="" /></p>
    );
  }
}

export default ChangelogTab;