import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ParametersTab from './components/ParametersTab/ParametersTab';
import DataPointsTab from './components/DataPointsTab/DataPointsTab';
import SettingsTab from './components/SettingsTab/SettingsTab';
import 'react-tabs/style/react-tabs.css';
import './parameters.scss';

class Parameters extends Component {
  state = {
    selectedTab: 'parameters'
  }

  selectTab = (tab) => {
    this.setState({ ...this.state, selectedTab: tab });
  }

  getCurrentTab = () => {
    switch (this.state.selectedTab) {
      case 'parameters':
        return <ParametersTab />
      case 'data-points':
        return <DataPointsTab />
      case 'settings':
        return <SettingsTab />  
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="parameters">
        <div className="row">
          <div className="tabordion" tabIndex="-1">
            <section id="parameters-section" tabIndex="0">
              <div onClick={() => this.selectTab('parameters')} tabIndex="0" className={this.state.selectedTab === 'parameters' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.parameters'} defaultMessage="Parameter" />
              </div>
              <div onClick={() => this.selectTab('data-points')} tabIndex="0" className={this.state.selectedTab === 'data-points' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.data-points'} defaultMessage="Data Points" />
              </div>
              <div onClick={() => this.selectTab('settings')} tabIndex="0" className={this.state.selectedTab === 'settings' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.settings'} defaultMessage="Settings" />
              </div>
            </section>
            <div className="tab-content" tabIndex="0">
              {this.getCurrentTab()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Parameters;

