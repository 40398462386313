import React from 'react';
import './input.scss';
import PropTypes from 'prop-types';

class Input extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    children: PropTypes.any,
    readOnly: PropTypes.any,
    autoComplete: PropTypes.string,
    disabled: PropTypes.any,
    maxLength: PropTypes.number,
    autoFocus: PropTypes.bool,
  }

  static defaultProps = {
    onChange() { },
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  state = {
    value: 'value' in this.props && this.props.value ? this.props.value : '',
    focused: false,
    error: null,
  }

  componentDidMount = () => {
    if (this.props.autoFocus && this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    return nextProps.value !== state.value
      ? {
        value: nextProps.value,
      }
      : null;
  }

  handleFocusChange = (e) => {
    if (this.autoFillInterVal) {
      clearInterval(this.autoFillInterVal);
      this.autoFillInterVal = null;
    }

    this.setState({
      focused: e.type === 'focus'
    });

    if (this.props.validator) {
      this.setState({ ...this.state, error: this.props.validator(this.state.value) });
    }

    if (!this.props.onBlur || e.type !== 'blur') {
      return;
    }

    if (this.props.error || (this.props.validator && this.props.validator(this.state.value))) {
      return;
    }

    this.props.onBlur(e);
  };

  handleAutoFill = (e) => {
    this.setState({
      focused: e.animationName === 'onAutoFillStart'
    });
  }

  handleChange = (e) => {
    const { props } = this;

    // For password fields, don't allow leading spaces
    // For other fields, don't allow leading spaces or multiple consecutive spaces

    let val = (e.target.value || '').replace(/^\s+/gi, '');

    if (e.target.type !== 'password') {
      val = val.replace(/\s+/gi, ' ');
    }

    this.setState({
      value: val,
    });

    props.onChange({
      target: {
        ...props,
        type: e.target.type,
        value: val,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  };

  render() {
    const { value, focused } = this.state;
    const {
      id,
      name,
      type,
      defaultValue,
      placeholder,
      label,
      error,
      readOnly,
      autoComplete,
      disabled,
      maxLength = 1000
    } = this.props;

    const setAutoComplete = autoComplete || 'new-password';

    const showErrorMessage = error || this.state.error ? true : false;

    if (readOnly === true) {
      return (
        <div className={'inputComponent' + (showErrorMessage ? ' inputComponent--has-error' : '')}>
          {label ? (<label className="inputComponent__label" htmlFor={id}>{label}</label>) : ''}

          {showErrorMessage && (<p className="inputComponent__error">{error || this.state.error}</p>)}

          <label htmlFor={this.props.id} className="inputComponent__placeholder__label">
            <span className={'inputComponent__placeholder' + (((value && value.length) || focused) ? ' focused' : '')}>
              {placeholder}
            </span>
            <input className={'inputComponent__field' + (showErrorMessage ? ' inputComponent__field--error' : '')}
              id={id}
              name={name}
              value={value}
              onBlur={this.handleFocusChange}
              onChange={e => this.handleChange(e)}
              onFocus={this.handleFocusChange}
              type={type}
              onAnimationStart={this.handleAutoFill}
              autoComplete="new-password"
              readOnly
              disabled={disabled}
            />
          </label>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className={'inputComponent' + (showErrorMessage ? ' inputComponent--has-error' : '')}>
          {label ? (<label className="inputComponent__label" htmlFor={id}>{label}</label>) : ''}

          {showErrorMessage && (<p className="inputComponent__error">{error || this.state.error}</p>)}

          <label htmlFor={this.props.id} className="inputComponent__placeholder__label">
            <span className={'inputComponent__placeholder' + (((value && value.length) || focused || defaultValue) ? ' focused' : '')}>
              {placeholder}
            </span>
            <input className={'inputComponent__field' + (showErrorMessage ? ' inputComponent__field--error' : '')}
              id={id}
              name={name}
              ref={this.inputRef}
              value={value}
              defaultValue={defaultValue}
              onBlur={this.handleFocusChange}
              onChange={e => this.handleChange(e)}
              onFocus={this.handleFocusChange}
              type={type}
              onAnimationStart={this.handleAutoFill}
              autoComplete={setAutoComplete}
              disabled={disabled}
              maxLength={maxLength}
            />
          </label>
          {this.props.children}
        </div>
      );
    }
  }
}

export default Input;