import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.scss';

const Button = ({ id, className, disabled, children, type, onClick }) => (
  <button id={id} className={classNames('button', className)} type={type} disabled={disabled} onClick={onClick}>
    {children}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onClick: PropTypes.func
};

Button.defaultProps = {
  id: '',
  className: '',
};

export default Button;
