import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './content.scss';

const Content = ({ children, className }) => (
  <div className={classNames('content', className)}>
    {children}
  </div>);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string
}

export default Content;