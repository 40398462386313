import {
    GET_ADDRESS_RESPONSE_SUCCEDED,
    CHANGE_ADDRESS_RESPONSE_SUCCEDED,
    CREATE_ADDRESS_RESPONSE_SUCCEDED,
    CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED,
    RESET_ADDRESS_FORM_ERROR_REQUEST
  } from './actions';
  
  const initialState = {
    address: null,
    addressError: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_ADDRESS_RESPONSE_SUCCEDED:
        return { ...state, address: action.address };
      case CHANGE_ADDRESS_RESPONSE_SUCCEDED:
        return { ...state, addressError: null, address: action.address };
      case CREATE_ADDRESS_RESPONSE_SUCCEDED:
        return { ...state, addressError: null, address: action.address };
      case CREATE_NEW_ADDRESS_ID_RESPONSE_SUCCEDED:
        return { ...state, address: { id: action.userProfile.addressId } };
      case RESET_ADDRESS_FORM_ERROR_REQUEST:
        return { ...state, addressError: null };
      default:
        return state;
    }
  };