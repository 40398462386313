import React from 'react';
import PropTypes from 'prop-types';

import './fade.scss';

const Fade = ({ children }) => (
  <div className="fade-bg" >
    {children}
  </div>
);

Fade.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
}

export default Fade;
