import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { compose } from 'redux';

import SignInLayoutRoute from './SignInLayout';
import SignOutLayoutRoute from './SignOutLayout';
import Login from '../pages/Login';
import NotFoundPage from "../pages/NotFound";
import Apps from '../pages/Apps';
import Intro from '../pages/Intro';
import Auth from '../pages/Auth';
import Parameters from "../pages/Parameters";
import Updates from '../pages/Updates';
import PrivacyPolicy from '../pages/LegalPages/PrivacyPolicy';
import TermsOfService from '../pages/LegalPages/TermsOfService';
import APIServicesAgreement from '../pages/LegalPages/APIServicesAgreement';
import ForgotPassword from '../pages/ForgotPassword';
//Hiding because some links for tutorials are not ready yet but will be use in future
// import Tutorial from '../pages/Tutorial/Tutorial';
import ProfilePage from '../pages/ProfilePage';

const routesDefinition = {
  anonymousRoutes: [
    { path: '/login', component: <SignOutLayoutRoute path="/login" component={Login} /> },
    { path: '/forgot-password', component: <SignOutLayoutRoute path="/forgot-password" component={ForgotPassword} /> },
    { path: '/privacy-policy', component: <SignOutLayoutRoute path="/privacy-policy" component={PrivacyPolicy} /> },
    { path: '/terms-of-service', component: <SignOutLayoutRoute path="/terms-of-service" component={TermsOfService} /> },
    { path: '/api-services-agreement', component: <SignOutLayoutRoute path="/api-services-agreement" component={APIServicesAgreement} /> },
  ],
  restrictedRoutes: [
    { path: '/', component: <SignInLayoutRoute path="/" exact component={Intro} /> },
    { path: '/apps', component: <SignInLayoutRoute path="/apps" component={Apps} /> },
    { path: '/intro', component: <SignInLayoutRoute path="/intro" component={Intro} /> },
    //Hiding because some links for tutorials are not ready yet but will be use in future
    // { path: '/tutorial', component: <SignInLayoutRoute path="/tutorial" component={Tutorial} /> },
    { path: '/auth', component: <SignInLayoutRoute path="/auth" component={Auth} /> },
    { path: '/parameters', component: <SignInLayoutRoute path="/parameters" component={Parameters} /> },
    { path: '/updates', component: <SignInLayoutRoute path="/updates" component={Updates} /> },
    { path: '/privacy-policy', component: <SignInLayoutRoute path="/privacy-policy" component={PrivacyPolicy} /> },
    { path: '/terms-of-service', component: <SignInLayoutRoute path="/terms-of-service" component={TermsOfService} /> },
    { path: '/api-services-agreement', component: <SignInLayoutRoute path="/api-services-agreement" component={APIServicesAgreement} /> },
    { path: '/profile-settings', component: <SignInLayoutRoute path="/profile-settings" component={ProfilePage} /> },
  ],
};

const Router = ({ match, location, userLoggedIn }) => {
  const routes = userLoggedIn
    ? routesDefinition.restrictedRoutes
    : routesDefinition.anonymousRoutes;

  const redirectTo = (!userLoggedIn && !match.isExact) ? '?redirectTo=' + location.pathname : '';

  return (
    <Switch>
      {routes.map((r, i) => ({ ...r.component, key: i }))}
      <Redirect to={userLoggedIn ? '/' : '/login' + redirectTo} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Router.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

const withConnect = connect(({ app }) => ({ ...app }));

export default compose(
  withRouter,
  withConnect,
)(Router);
