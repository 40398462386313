import * as storage from '../../localStorage';
import * as api from '../../api';

export const USER_AUTH_STATUS_CHANGED = 'app/USER_AUTH_STATUS_CHANGED';

export const initUser = () => {
  const tokens = storage.getAuthTokens();
  const url = {
    WEB_URL: api.WEB_URL,
  };
  return { type: USER_AUTH_STATUS_CHANGED, loggedIn: tokens !== null, url: url };
};

export const GET_USER_INFO_REQUEST = 'app/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_RESPONSE = 'app/GET_USER_INFO_RESPONSE';
export const getUserInfo = () => async dispatch => {
  dispatch({ type: GET_USER_INFO_REQUEST });

  const response = await api.getUserInfo();
  if (response.status === 200) {
    dispatch({ type: GET_USER_INFO_RESPONSE, userInfo: response.data, });
  }
};

export const SET_APP_LOADED_STATUS = 'app/SET_APP_LOADED_STATUS';
export const setAppLoadedStatus = status => ({ type: SET_APP_LOADED_STATUS, status });

export const CHANGE_REDIRECT_STATUS = 'app/CHANGE_REDIRECT_STATUS';
export const changeRedirectStatus = status => ({ type: CHANGE_REDIRECT_STATUS, status });

export const GET_COUNTRIES_REQUEST = 'app/GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RESPONSE = 'app/GET_COUNTRIES_RESPONSE';
export const getCountries = () => async dispatch => {
  dispatch({ type: GET_COUNTRIES_REQUEST });

  const res = await api.getCountriesList();
  const countries = res.data;
  dispatch({ type: GET_COUNTRIES_RESPONSE, countries: countries });
};
