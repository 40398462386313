import { combineReducers } from 'redux';

import app from '../components/AppInitializer/reducers';
import language from '../components/LanguageSelector/reducers';
import login from '../pages/Login/reducers';
import forDeveloper from '../pages/Apps/reducers';
import profilePage from '../pages/ProfilePage/reducers';
import profileTab from '../pages/ProfilePage/components/ProfileTab/reducers';
import addressTab from '../pages/ProfilePage/components/AddressTab/reducers';


const rootReducer = combineReducers({
  app,
  language,
  login,
  forDeveloper,
  profilePage,
  profileTab,
  addressTab
});

export const createRootReducers = (injectedReducers = {}) => {
  return combineReducers({
    app,
    language,
    login,
    forDeveloper,
    profilePage,
    profileTab,
    addressTab,
    ...injectedReducers
  });
};

export default rootReducer;
