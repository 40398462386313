import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class DataPointsTab extends React.Component {

  render() {
    return (
    <p><FormattedHTMLMessage id="wiki.data-points" defaultMessage="" /></p>
);
  }
}

export default DataPointsTab;