import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import InputWithCopy from '../../../../components/InputWithCopy';
import './app-list-item.scss';
import Group from './components/Group';
import { callbackURLRegex } from '../../../../components/constants/constants';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import CreateNewClientSecretConfirmation from '../CreateNewClientSecretConfirmation';
import ExpireClientSecretConfirmation from '../ExpireClientSecretConfirmation';

const oneHour = 3600000;

const schema = yup.object().shape({
  appName: yup.string()
    .trim()
    .min(4, 'App Name cannot be less than 4 characters')
    .max(50, 'App Name cannot be longer than 50 characters')
    .required('App Name is mandatory field'),
  appDescription: yup.string()
    .trim()
    .min(4, 'App Description cannot be less than 4 characters')
    .max(80, 'App Description cannot be longer than 80 characters')
    .required('App Description is mandatory field'),
  callbackUrl: yup.string()
    .matches(callbackURLRegex, 'Callback Url must be a valid URL').nullable()
});

const AppListItem = ({ toggleDeleteModal, app, updateClient, onSubmit, isValueSaved, addClientSecret, expireClientSecret }) => {
  const [isChanged, setChanged] = useState(false);
  const [form, setState] = useState({
    appName: app.name,
    appDescription: app.description,
    callbackUrl: app.redirectUrl
  })
  const [showNewClientSecretConfirmation, setShowNewClientSecretConfirmation] = useState(false);
  const [showExpireClientSecretConfirmation, setShowExpireClientSecretConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const secrets = [...app.secrets].sort((a, b) => new Date(b.validity) - new Date(a.validity));
  const oldClientSecret = secrets.length > 1 && secrets[secrets.length - 1];
  const [isOldClientSecretValid, setIsOldClientSecretValid] = useState(false);

  useEffect(() => {
    let interval;
    if (oldClientSecret) {
      const validityDifference = moment.utc(oldClientSecret.validity).diff(moment.utc());
      const intervalTimeout = Math.min(validityDifference, oneHour);
      setIsOldClientSecretValid(moment.utc() < moment.utc(oldClientSecret.validity));

      interval = setInterval(() => {
        setIsOldClientSecretValid(moment.utc() < moment.utc(oldClientSecret.validity));
      }, intervalTimeout)
    } else {
      setIsOldClientSecretValid(false);
    }

    return () => {
      interval && clearInterval(interval);
    }
  }, [oldClientSecret])

  const updateField = e => {
    const { name, value } = e.target;
    const normalizedValue = value.trim();
    setState({
      ...form,
      [name]: normalizedValue === '' ? null : normalizedValue,
      isChanged: setChanged(true)
    })
  }

  const handleConfirmNewClientSecret = async () => {
    setIsLoading(true);
    await addClientSecret(app.id);
    setShowNewClientSecretConfirmation(false);
    setIsLoading(false);
  }

  const handleConfirmExpireClientSecret = async () => {
    setIsLoading(true);
    await expireClientSecret(oldClientSecret.clientId, oldClientSecret.id);
    setShowExpireClientSecretConfirmation(null);
    setIsLoading(false);
  }

  return (
    <div className="AppListItem">
      <h3 className="apps-heading">Applications</h3>
      <Formik
        initialValues={{
          appName: app.name,
          appDescription: app.description,
          callbackUrl: app.redirectUrl
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          isSubmitting,
        }) => (
          <>
            <div className="row">
              <div className="col-lg-12">
                {(isSubmitting)}
              </div>
            </div>
            <h4><FormattedMessage id="q" defaultMessage="Description" /></h4>
            <form onSubmit={updateClient} className="createNewAppForm">
              {(!isSubmitting) && <div>
                <div className="formInput formInput--half formInput--border">
                  <FormattedMessage id="apps.appName">
                    {placeholder =>
                      <Input
                        name="appName"
                        id="appName"
                        type="text"
                        defaultValue={values.appName}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onBlur={updateField}
                        error={errors.appName}
                      />}
                  </FormattedMessage>
                </div>
                <div className="formInput formInput--half formInput--border">
                  <FormattedMessage id="apps.description">
                    {placeholder =>
                      <Input
                        placeholder={placeholder}
                        name="appDescription"
                        id="appDescription"
                        defaultValue={values.appDescription}
                        onChange={handleChange}
                        onBlur={updateField}
                        error={errors.appDescription}
                      />}
                  </FormattedMessage>
                </div>
                <div className="formInput formInput--half formInput--border">
                  <FormattedMessage id="apps.callbackUrl">
                    {placeholder =>
                      <Input
                        placeholder={placeholder}
                        name="callbackUrl"
                        id="callbackUrl"
                        defaultValue={values.callbackUrl || ''}
                        rows={10} style={{ width: '100%' }}
                        error={errors.callbackUrl}
                        onChange={handleChange}
                        onBlur={updateField}
                      />}
                  </FormattedMessage>
                </div>
                <br />
                <h4><FormattedMessage id="q" defaultMessage="Credentials" /></h4>
                <div className="boxContainer">
                  <div className="appName">
                    <InputWithCopy text={app.id} id="apps.appID" />
                  </div>
                  <br />
                  <div className="appName">
                    <InputWithCopy text={secrets[0]?.secret} id="apps.restAPIKey" hide />
                  </div>
                  {oldClientSecret && <div className="appName inputWithCopyWrapper">
                    <InputWithCopy text={oldClientSecret.secret} id="apps.appIDValidUntil" values={{ dateTime: moment.utc(oldClientSecret.validity).local().format('YYYY-MM-DD HH:mm:ss') }} hide />
                    <OverlayTrigger placement="left" trigger="hover" overlay={
                      <Popover>
                        <FormattedMessage
                          id="apps.invalidateClientSecret"
                          defaultMessage="Invalidate Client Secret" />
                      </Popover>}>
                      <button className="invalidateBtn" type="button" onClick={() => setShowExpireClientSecretConfirmation(true)} aria-label="Invalidate Client Secret">
                        <span className="glyphicon glyphicon-trash" aria-hidden="true" />
                      </button>
                    </OverlayTrigger>
                  </div>}
                </div>

                {app.groups && app.groups.some(x => x) && <div>
                  <br />
                  <h4><FormattedMessage id="q" defaultMessage="Groups" /></h4>
                  <div className="boxContainer">
                    <div className="appName">
                      {app.groups.map(x => <Group key={x.name} name={x.name} />)}
                    </div>
                  </div>
                </div>}

                {isValueSaved && <p className="editAppInfo__savedMessage">
                  <FormattedMessage id="savedMessage" defaultMessage="Saved!" />
                </p>}
                <div className="footer">
                  <OverlayTrigger placement="right" trigger={isOldClientSecretValid && 'hover'} overlay={
                    <Popover>
                      <FormattedMessage
                        id="apps.invalidCreateNewClientSecret"
                        defaultMessage="It`s prohibited to create a new client secret until the old one is valid" />
                    </Popover>}>
                    <div className="overlay-wrapper" disabled>
                      <Button className="btn btn-primary" disabled={isOldClientSecretValid} type="button" onClick={() => setShowNewClientSecretConfirmation(true)}>
                        <FormattedMessage id="apps.createNewClientSecret" defaultMessage="Create new client secret" />
                      </Button>
                    </div>
                  </OverlayTrigger>
                  <Button className="btn btn-success" type="submit" disabled={!isChanged || isSubmitting} onClick={() => {
                    updateClient(app.id, form.appName, form.appDescription, form.callbackUrl);
                    setState({ isChanged: setChanged(false) })
                  }}>
                    <FormattedMessage id="button.save" defaultMessage="Save" />
                  </Button>
                  <Button className="btn btn-danger" type="button" onClick={() => {
                    toggleDeleteModal(app);
                  }}>
                    <FormattedMessage id="button.remove" defaultMessage="Remove" />
                  </Button>
                </div>
                <div className="clearfix"></div>
              </div>}
            </form>
          </>
        )}
      </Formik>
      <CreateNewClientSecretConfirmation
        isShown={showNewClientSecretConfirmation}
        isDisabled={isLoading}
        onCancel={() => setShowNewClientSecretConfirmation(false)}
        onConfirm={handleConfirmNewClientSecret} />
      <ExpireClientSecretConfirmation
        isShown={showExpireClientSecretConfirmation}
        isDisabled={isLoading}
        onCancel={() => setShowExpireClientSecretConfirmation(false)}
        onConfirm={handleConfirmExpireClientSecret} />
    </div>
  )
};

AppListItem.propTypes = {
  updateClient: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  toggleDeleteModal: PropTypes.func.isRequired,
  isValueSaved: PropTypes.bool.isRequired,
  addClientSecret: PropTypes.func.isRequired,
  expireClientSecret: PropTypes.func.isRequired,
  app: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    redirectUrl: PropTypes.string,
    groups: PropTypes.arrayOf({
      name: PropTypes.string
    }),
    secrets: PropTypes.arrayOf({
      secret: PropTypes.string,
      clientId: PropTypes.string,
      validity: PropTypes.string
    }),
  })
};

export default injectIntl(AppListItem);
