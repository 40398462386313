import React, { Component } from 'react';
import HamburgerMenuItem from './components/HamburgerMenuItem';
import HamburgerMenuButton from './components/HamburgerMenuButton';


import './hamburger-menu.scss';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { logoutUser } from '../../../../../../pages/Login/actions';

class HamburgerMenu extends Component {
  state = {
    menuOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleMenuClick() {
    if (!this.state.menuOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ menuOpen: false });
  };

  handleLinkClick = (e, path) => {
    if (path === "logout") {
      e.preventDefault();
      this.props.logoutUser()
    }
  };

  render() {
    const menuItems = this.props.menuItems.filter(item => !item.hidden).map((val, index) => (
      <HamburgerMenuItem key={index} name={val.name} id={val.id} path={val.path} action={val.action} onLinkClick={this.handleLinkClick}>
        {val.children}
      </HamburgerMenuItem>
    ));

    const { menuOpen } = this.state;
    return (
      <div ref={node => { this.node = node }} >
        <HamburgerMenuButton open={menuOpen} onClick={() => this.handleMenuClick()} />
        {menuOpen &&
        <div className="hamburgerMenu">
          <div className="hamburgerMenu__list">
            {menuItems}
          </div>
        </div>}
      </div>
    )
  }
}

HamburgerMenu.propTypes = {
  menuItems: PropTypes.array,
  logoutUser: PropTypes.func.isRequired
};

export default connect(
  ({
     logout,
   }) => ({
    ...logout,
  }),
  {
    logoutUser
  }
)(HamburgerMenu);
