export const configNames = {
  Local: 'Local',
  DevCloud: 'Dev-Cloud',
  TestCloud: 'Test-Cloud',
  ProdCloud: 'Prod-Cloud',
};

const areWeTestingWithJest = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

export const getConfig = () => {
  return process.env;
};

export const getConfigName = () => {
  if (areWeTestingWithJest()) return configNames.Local;
  return process.env.REACT_APP_ENV;
};
