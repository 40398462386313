import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import IntroductionTab from '../Intro/components/IntroductionTab/IntroductionTab';
import QuickStartGuideTab from '../Tutorial/components/QuickStartGuideTab/QuickStartGuideTab';
import DataFormatTab from '../Intro/components/DataFormatTab/DataFormatTab';
import ParametersTab from '../Intro/components/ParametersTab/ParametersTab';
import ErrorMessagesTab from '../Intro/components/ErrorMessagesTab/ErrorMessagesTab';
import HTTPRedirectsTab from '../Intro/components/HTTPRedirectsTab/HTTPRedirectsTab';
import PaginationTab from '../Intro/components/PaginationTab/PaginationTab';
import RateLimitingClientAbuseTab from '../Intro/components/RateLimitingClientAbuseTab/RateLimitingClientAbuseTab';
import LanguageTab from '../Intro/components/LanguageTab/LanguageTab';
import VersioningTab from '../Intro/components/VersioningTab/VersioningTab';
import 'react-tabs/style/react-tabs.css';
import './intro.scss';

class Intro extends Component {
  state = {
    selectedTab: 'intro'
  }

  selectTab = (tab) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.setState({ ...this.state, selectedTab: tab });
  }

  getCurrentTab = () => {
    switch (this.state.selectedTab) {
      case 'intro':
        return <IntroductionTab />
      case 'quick-start-guide':
        return <QuickStartGuideTab />
      case 'data-format':
        return <DataFormatTab />
      case 'parameters':
        return <ParametersTab />
      case 'error-messages':
        return <ErrorMessagesTab />
      case 'http-redirects':
        return <HTTPRedirectsTab />
      case 'pagination':
        return <PaginationTab />
      case 'rate-limiting-client-abuse':
        return <RateLimitingClientAbuseTab />
      case 'language':
        return <LanguageTab />
      case 'versioning':
        return <VersioningTab />
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="intro">
        <div className="row">
          <div className="tabordion" tabIndex="-1">
            <section id="intro-section" tabIndex="0">
              <div onClick={() => this.selectTab('intro')} tabIndex="0" className={this.state.selectedTab === 'intro' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.intro'} defaultMessage="Introduction" />
              </div>
              <div onClick={() => this.selectTab('quick-start-guide')} tabIndex="0" className={this.state.selectedTab === 'quick-start-guide' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.quick-start-guide'} defaultMessage="Quick Start Guide" />
              </div>
              <div onClick={() => this.selectTab('data-format')} tabIndex="0" className={this.state.selectedTab === 'data-format' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.data-format'} defaultMessage="Data Format" />
              </div>
              <div onClick={() => this.selectTab('parameters')} tabIndex="0" className={this.state.selectedTab === 'parameters' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.parameters'} defaultMessage="Parameters" />
              </div>
              {<div onClick={() => this.selectTab('error-messages')} tabIndex="0" className={this.state.selectedTab === 'error-messages' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.error-messages'} defaultMessage="Error Messages" />
              </div>}
              <div onClick={() => this.selectTab('http-redirects')} tabIndex="0" className={this.state.selectedTab === 'http-redirects' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.http-redirects'} defaultMessage="HTTP Redirects" />
              </div>
              <div onClick={() => this.selectTab('pagination')} tabIndex="0" className={this.state.selectedTab === 'pagination' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.pagination'} defaultMessage="Pagination" />
              </div>
              <div onClick={() => this.selectTab('rate-limiting-client-abuse')} tabIndex="0" className={this.state.selectedTab === 'rate-limiting-client-abuse' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.rate-limiting-client-abuse'} defaultMessage="Rate Limiting and client abuse" />
              </div>
              <div onClick={() => this.selectTab('language')} tabIndex="0" className={this.state.selectedTab === 'language' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.language'} defaultMessage="Language" />
              </div>
              <div onClick={() => this.selectTab('versioning')} tabIndex="0" className={this.state.selectedTab === 'versioning' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.versioning'} defaultMessage="Versioning" />
              </div>
            </section>
            <div className="tab-content" tabIndex="0">
              {this.getCurrentTab()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
