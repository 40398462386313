import 'react-app-polyfill/ie9';

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import store from './store';
import { history } from './store/configureStore';
import Root from './Root';

import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.

import { registerAuthHeaderInterceptor } from './api/requestInterceptor';

require('./assets/img/favicon.ico'); // Tell webpack to load favicon.ico

registerAuthHeaderInterceptor(store);

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('root')
);
