import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { push } from 'connected-react-router';

import { WEB_REGISTER_URL } from '../../api';

import { login, validateUserCredentials } from './actions';

import './login.scss';
import Input from "../../components/Input";


const schema = yup.object().shape({
  username: yup.string().trim()
    .email('Username should be email address')
    .required('Username is mandatory field'),
  password: yup.string().trim().required('Password is mandatory field'),
});

const requestErrors = {
  'Supplied password is not correct!': 'Invalid username or password',
  'There is no user with such email!': 'Invalid username or password',
  'unknown': 'An error has occurred. Try again later.',
  'invalid request': 'Invalid username or password'
};

const LoginForm = ({ onSubmit, requestError, isSubmitting }) => (
  <Formik
    initialValues={{ username: '', password: '' }}
    validationSchema={schema}
    onSubmit={onSubmit}
  >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (

      <form onSubmit={handleSubmit} className="loginForm">
        <div className="loginForm__paragraph loginForm__paragraph--no-padding-top">
          <p className="loginForm__paragraph--gray">
            To access the myUplink API pages, please
          </p>
          <p>log in with your myUplink user credentials.</p>
          <p className="loginForm__paragraph--gray">
            If you do not have a myUplink user account,
          </p>
          <p>
            register one at <a href={WEB_REGISTER_URL}><FormattedMessage id='login.registerURL' defaultMessage="myuplink.com" /></a>.
          </p>
        </div>
        <div className="ml-3">
          <h3 className="loginForm__heading"><FormattedMessage id='login.login' defaultMessage="Log in" /></h3>
        </div>
        <div className="loginForm__inputWrapper">
          <div className="formInput formInput--border">
            <FormattedMessage id='login.input-name' defaultMessage='E-mail'>
              {placeholder =>
                <Input
                  placeholder={placeholder}
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  error={errors.username && touched.username && errors.username} />}
            </FormattedMessage>
          </div>

          <div className="formInput formInput--border">
            <FormattedMessage id='login.input-password' defaultMessage='Password'>
              {placeholder =>
                <Input
                  placeholder={placeholder}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password && touched.password && errors.password} />}
            </FormattedMessage>
          </div>
          <div className="forgot-link-wrapper">
            <a className="form__link" href="/forgot-password">
              <FormattedMessage id="login.forgot-password" defaultMessage="Forgot your password?" />
            </a>
          </div>
          {requestError && <p className="text-danger">{requestErrors[requestError]}</p>}
          <div className="formWrapper--spacer formWrapper--margin-top"></div>
          <div className="loginForm__buttonWrapper formInput__button clearfix">
            <button className="button button--secondary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="login.login" defaultMessage="Login" />
            </button>
          </div>
        </div>
      </form>
    )}
  </Formik>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
};

class Login extends React.Component {

  static propTypes = {
    push: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    error: PropTypes.string,
    validateUserCredentials: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    acceptedAgreements: PropTypes.object,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    showAgreementsModal: false,
    email: null,
    password: null,
    showMigrationConfirmation: false,
    showLegalInfoModal: false,
    legalInfoType: null,
  };

  onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const username = values.username.trim();
    const password = values.password.trim();
    const validateUserCredentialsResult = await this.props.validateUserCredentials(username, password);

    if (validateUserCredentialsResult.payload) {
      await this.props.login(username, password);
      this.props.push('/');
      return;
    }

    setSubmitting(false);
  };

  render() {
    return (
      <div className="Login">
        <LoginForm
          push={this.props.push}
          onSubmit={this.onSubmit}
          requestError={this.props.error ? this.props.error.response.data.description : ''}
          isSubmitting={this.props.loading} />
      </div>

    );
  }
}
export default connect(
  ({
     login,
     register,
   }) => ({
    ...login,
    register,
  }),
  {
    push,
    login,
    validateUserCredentials,
  }
)(Login);
