import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../../Logo';
import Navigation from '../Navigation';
import './header.scss';


const Header = ({ url }) => {
  return (
    <header className="header">
      <div className="header-inner page-centered">
        <Logo />
        <Navigation url={url} />
      </div>
    </header>
  )
}

Header.propTypes = {
  url: PropTypes.object.isRequired
}

export default Header;