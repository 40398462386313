import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class ErrorMessagesTab extends React.Component {

  render() {
    return (
      <p><FormattedHTMLMessage id="wiki.error-messages" defaultMessage="" /></p>
    );
  }
}

export default ErrorMessagesTab;