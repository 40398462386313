import { API } from '../api';
import { getAuthTokens, putAuthTokens } from '../localStorage';
import { logoutUser, reissueAuthToken } from '../pages/Login/actions';

export const registerAuthHeaderInterceptor = store => {
  API.interceptors.request.use(config => {
    const tokens = getAuthTokens();
    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens.access_token}`;
    }

    const { language } = store.getState();
    const langId = language.selectedLanguage || 'en-US';
    config.headers['Accept-Language'] = langId;

    return config;
  });

  API.interceptors.response.use(null, async error => {
    if (error.response.status === 401) {
      const tokens = getAuthTokens();
      try {
        const authResponse = await reissueAuthToken(tokens.refresh_token);
        putAuthTokens(authResponse.data);
      } catch (e) {
        store.dispatch(logoutUser());
        return Promise.reject(error);
      }

      return API(error.config);
    }
    if(!error.response.data)
      error.response.data = {};
    return Promise.reject(error);
  });
};
