import React from 'react';
import './checkbox.scss';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    error: PropTypes.string,
    thin: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    children: PropTypes.object
  };

  static defaultProps = {
    thin: false,
    className: "",
    onChange() { },
  };

  state = {
    checked: !!this.props.checked,
    oldCheckedProp: !!this.props.checked,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked !== prevState.oldCheckedProp) {
      return {
        ...prevState,
        checked: !!nextProps.checked,
        oldCheckedProp: !!nextProps.checked,
      };
    }
    return prevState;
  }

  handleChange = (e) => {
    const { props } = this;
    if (props.disabled) {
      return;
    }

    this.setState({ checked: e.target.checked });

    props.onChange({
      target: {
        ...props,
        type: e.target.type,
        checked: e.target.checked,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  };

  render() {
    const { error, thin, className, disabled } = this.props;
    const { checked } = this.state;
    let checkboxClasses = [
      "checkboxComponent ",
      "checkboxComponent--bottom ",
      "checkboxComponent--underline ",
      (thin ? "checkboxComponent--thin " : ""),
      (error ? "checkboxComponent--error " : ""),
      className ? className : ""
    ].join("");

    return (
      <div className={checkboxClasses}>
        <input checked={checked} id={this.props.id} disabled={disabled} className="checkboxComponent__input" onChange={this.handleChange} type="checkbox" />
        <label htmlFor={this.props.id} className={`checkboxComponent__label ${disabled ? 'checkboxComponent__label_disabled' : ''}`} disabled={disabled}>
          {this.props.title && <span className={`checkboxComponent__text ${disabled ? 'checkboxComponent__text_disabled' : ''}`}>{this.props.title}</span>}
          {!this.props.title && this.props.children && <div className="checkboxComponent__children">{this.props.children}</div>}
        </label>
      </div>
    );
  }
}

export default Checkbox;
