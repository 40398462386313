import axios from 'axios';

import { getConfig } from './config';

export const API_URL = getConfig().REACT_APP_API_URL || '';
export const WEB_URL = getConfig().REACT_APP_WEB_URL || '';
export const WEB_PRO_URL = getConfig().REACT_APP_WEB_PRO_URL || '';
export const WEB_DEV_URL = getConfig().REACT_APP_WEB_DEV_URL || '';
export const WEB_REGISTER_URL = getConfig().REACT_APP_WEB_REGISTER_URL || '';
export const ENV = getConfig().REACT_APP_ENV || '';
export const PUBLIC_API_URL = getConfig().REACT_APP_PUBLIC_API_URL || '';
export const READABLE_WEB_URL = getConfig().REACT_APP_READABLE_WEB_URL || '';
export const CLIENT_ID = getConfig().REACT_APP_CLIENT_ID || '';

const CONFIG_JSON = {
  headers: {
    "Content-Type": "application/json",
  }
};

export const getUserInfo = () => {
  return API.get(`${API_URL}/v2/users/me`);
};

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    ...CONFIG_JSON.headers,
  },
});

export const sendRecoveryLink = email => {
  const body = { email };
  return API.post(`${API_URL}/v2/users/recovery-link/send`, body, CONFIG_JSON)
};

export const verifyRecoveryToken = token => {
  const body = { token };
  return API.post(`${API_URL}/v2/users/recovery-link/verify`, body, CONFIG_JSON)
};

export const changePassword = (id, oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };

  return API.post(`${API_URL}/v2/users/${id}/password`, body, CONFIG_JSON);
};

export const resetPassword = (token, password) => {
  const body = { token, password };
  return API.post(`${API_URL}/v2/users/recovery-link/reset-password`, body, CONFIG_JSON)
};

export const getUserProfile = (id) => {
  return API.get(`${API_URL}/v2/users/${id}/profile`);
};

export const getTimeZones = () => {
  return API.get(`${API_URL}/v2/timezones`);
};

export const updateUserProfile = (id, profile) => {
  return API.put(`${API_URL}/v2/users/${id}/profile`, profile, CONFIG_JSON);
};

export const getAddressInfo = (addressId) => {
  return API.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const addAddressIdToUser = (userId, addressId) => {
  const body = {
    addressId: addressId
  };

  return API.patch(`${API_URL}/v2/users/${userId}`, body, CONFIG_JSON);
};

export const changeAddressInfo = (address) => {
  return API.put(`${API_URL}/v2/addresses/user/${address.id}`, address, CONFIG_JSON);
};

export const createAddressInfo = (address) => {

  return API.post(`${API_URL}/v2/addresses/user`, address, CONFIG_JSON);
};

export const changeEmail = (id, oldEmail, newEmail, password) => {
  const body = {
    userId: id,
    oldEmail: oldEmail,
    newEmail: newEmail,
    password: password
  };

  return API.post(`${API_URL}/v2/users/${id}/email`, body, CONFIG_JSON);
};

export const deleteUserAccount = async (userId, password) => {
  return await API.delete(`${API_URL}/v2/users/${userId}`, { data: { password } });
};

export const validateUserCredentials = (username, password) => {
  const body = {
    grant_type: 'password',
    email: username,
    password: password
  };
  return API.post(`${API_URL}/v2/users/validate`, body, CONFIG_JSON)
};

export const getCountriesList = () => (API.get(`${API_URL}/v2/countries`));
