import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class DataFormatTab extends React.Component {

  render() {
    return (
      <p><FormattedHTMLMessage id="wiki.data-format" defaultMessage="" /></p>
    );
  }
}

export default DataFormatTab;