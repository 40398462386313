import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Title from '../../components/Title';
import Spinner from '../../components/Spinner';

import { getUserInfo, changeRedirectStatus } from '../../components/AppInitializer/actions';
import {
  changePassword,
  resetEmailError,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
} from './actions';
import ProfileTab from './components/ProfileTab/ProfileTab';
import DeleteAccountTab from './components/DeleteAccountTab/DeleteAccountTab';
import ChangePasswordForm from './components/ChangePasswordForm';
import './profilePage.scss';

class ProfilePage extends React.Component {
  state = {
    isLoaded: false,
    isEmailEditShown: false,
    isEmailConfirmationSent: false,
    isAddressEditShown: false,
    isPasswordChanged: false,
    selectedTab: 'profile'
  }

  selectTab = (tab) => {
    const isDemo = (this.props.userInfo && this.props.userInfo.isDemo) || false;

    if (isDemo && (tab === 'email' || tab === 'password' || tab === 'delete')) {
      return false;
    }

    this.setState({ ...this.state, selectedTab: tab });
  }

  async componentDidMount() {
    await this.props.getUserInfo();
    this.setState({ ...this.state, isLoaded: true });
  }

  getCurrentTab = () => {
    const userInfo = this.props.userInfo;
    switch (this.state.selectedTab) {
      case 'profile':
        return <ProfileTab
          userInfo={userInfo}
          addressId={userInfo.addressId}
          requestError={this.props.profileTab.profileError} />
      case 'password':
        return <ChangePasswordForm
          requestError={this.props.profilePage.passwordError}
          isChanged={this.state.isPasswordChanged}
          onSubmit={this.onPasswordSubmit} />;
      case 'delete':
        return <DeleteAccountTab
          deleteError={this.props.profilePage.deleteError}
          deleteUser={this.props.deleteAccount}
          resetDeleteError={this.props.resetDeleteError}
          userId={userInfo.id}
          userEmail={userInfo.email}
          history={this.props.history}
          validatePasswordError={this.props.profilePage.validatePasswordError}
          validateUserCredentials={this.props.validateUserCredentials}
          changeRedirectStatus={this.props.changeRedirectStatus} />;
      default:
        return null;
    }
  }

  onPasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (await this.props.changePassword(this.props.userInfo.id, values.oldPassword.trim(), values.newPassword.trim())) {
      this.setState({ ...this.state, isPasswordChanged: true });
      resetForm();
      setSubmitting(false);
      setTimeout(() => {
        this.setState({ ...this.state, isPasswordChanged: false });
      }, 2000);
    }
    else {
      setSubmitting(false);
    }
  }

  render() {
    const isDemo = this.props.userInfo && this.props.userInfo.isDemo;
    const isDisabledClassName = isDemo ? 'disabled' : ''

    if (!this.state.isLoaded) {
      return <Spinner />;
    }

    return (
      <div className="page-content">
        <div className="profile-page">
          <Title titleTranslationId="profile-settings.title" defaultMessage="Profile Settings" />
          <div className="tabordion">
            <section className="profile-section" id="profile-section">
              <div onClick={() => this.selectTab('profile')} className={this.state.selectedTab === 'profile' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.profile'} defaultMessage="Profile" />
              </div>
              <div onClick={() => this.selectTab('password')} className={classNames(this.state.selectedTab === 'password' ? 'option-label selected' : 'option-label', isDisabledClassName)}>
                <FormattedMessage id={'label.password'} defaultMessage="Password" />
              </div>
              <div onClick={() => this.selectTab('delete')} className={classNames(this.state.selectedTab === 'delete' ? 'option-label selected' : 'option-label', isDisabledClassName)}>
                <FormattedMessage id={'label.delete'} defaultMessage="Remove account" />
              </div>
            </section>
            <div className="tab-content">
              {this.getCurrentTab()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetEmailError: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  profilePage: PropTypes.object,
  userInfo: PropTypes.object,
  validateUserCredentials: PropTypes.func.isRequired,
  changeRedirectStatus: PropTypes.func.isRequired,
  profileTab: PropTypes.object,
}

export default connect(
  ({
    app: { userInfo },
    profilePage,
    profileTab,
  }) => ({
    userInfo,
    profilePage,
    profileTab,
  }),
  {
    getUserInfo,
    changePassword,
    resetEmailError,
    deleteAccount,
    resetDeleteError,
    validateUserCredentials,
    changeRedirectStatus
  }
)(ProfilePage);
