import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditAddressInfo from './EditAddressInfo';
import { getAddressInfo, changeAddressInfo, createAddressInfo } from './actions';
import { v4 as uuid } from 'uuid';
import Spinner from '../../../../components/Spinner';

class AddressTab extends React.Component {
  state = {
    isLoaded: false
  }

  async componentDidMount() {
    if (this.props.addressId) {
      await this.props.getAddressInfo(this.props.addressId);
    }
    this.setState({ ...this.state, isLoaded: true });
  }

  render() {
    return (
      <>
        {!this.state.isLoaded ?
          <Spinner dark />
          : this.props.values && <EditAddressInfo
            addressId={this.props.addressId}
            fullAddress={this.props.addressTab.address}
            countries={this.props.countries}
            requestError={this.props.addressTab.addressError}
            onCancel={this.onCancelEditing}
            onSubmit={this.onAddressSubmit}
            values={this.props.values}
            errors={this.props.errors}
            touched={this.props.touched}
            handleChange={this.props.handleChange}
            handleBlur={this.props.handleBlur}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched} />}
      </>
    );
  }

  onAddressSubmit = async (values) => {
    const newValues = {
      city: values.city.trim(),
      country: values.country,
      id: values.id,
      lineOne: values.lineOne.trim(),
      lineTwo: values.lineTwo && values.lineTwo.trim(),
      postalCode: values.postalCode.trim(),
      region: values.region && values.region.trim()
    }
    var addressId = this.props.addressId || uuid();
    if (this.props.addressId) {
      (await this.props.changeAddressInfo(newValues));
    }
    else {
      (await this.props.createAddressInfo(addressId, newValues));
    }
  }
}

AddressTab.propTypes = {
  addressId: PropTypes.string.isRequired,
  addressTab: PropTypes.object,
  getAddressInfo: PropTypes.func.isRequired,
  changeAddressInfo: PropTypes.func.isRequired,
  createAddressInfo: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
}

export default connect(
  ({
    app: { countries },
    addressTab,
    profileTab
  }) => ({
    countries,
    addressTab,
    profileTab
  }),
  {
    getAddressInfo,
    changeAddressInfo,
    createAddressInfo,
  }
)(AddressTab);