import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import TopMenu from './components/TopMenu';

import './signed-in-layout.scss';
import PropTypes from "prop-types";
import Footer from '../Footer';

class SignInLayout extends Component {
  componentDidMount() {
    document.body.classList.add('signed-in');
  }
  componentWillUnmount() {
    document.body.classList.remove('signed-in');
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <div>
        <TopMenu />
        <section id="main-content">
          <Route {...rest} render={matchProps => (
            <div className="container-fluid">
              <Component {...matchProps} />
            </div>
          )}>
          </Route>
          <div className="push" />
        </section>
        <Footer />
      </div>
    );
  }

  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func]).isRequired,
  };
}

export default SignInLayout;
