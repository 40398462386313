import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import LocalizationWrapper from './localization/LocalizationWrapper';
import App from './components/App';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <LocalizationWrapper>
          <ConnectedRouter history={history}>
            <App/>
          </ConnectedRouter>
        </LocalizationWrapper>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func]).isRequired,
  history: PropTypes.object.isRequired
};
