import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import DesktopMenu from './components/DesktopMenu';
import HamburgerMenu from './components/HamburgerMenu';

import myUplinkLogo from '../../../../assets/img/myuplinkdevs.png';
import { logoutUser } from '../../../../pages/Login/actions';
import { Permissions } from '../../../AppInitializer/reducers';

import './topmenu.scss';
import profileIcon from '../../../../assets/img/profile.svg';
import { PUBLIC_API_URL } from "../../../../api";

const profileMenu = [
  {
    name: 'Profile settings',
    id: 'profile-settings.title',
    path: '/profile-settings',
  },
  {
    name: 'Log out',
    id: 'menu.logout',
    path: '/logout'
  }
]

const MenuLayout = ({ onLogOut, permissions }) => ([
  {
    name: 'Documentation',
    id: 'menu.documentation',
    align: 'left',
    children: [
      {
        name: 'Introduction',
        id: 'menu.documentation.introduction',
        path: '/intro'
      },
      //Hiding because some links for tutorials are not ready yet but will be use in future
      // {
      //   name: 'Tutorial',
      //   id: 'menu.documentation.tutorial',
      //   path: '/tutorial'
      // },
      {
        name: 'Authentication',
        id: 'menu.documentation.authentication',
        path: '/auth'
      },
      {
        name: 'Parameters',
        id: 'menu.documentation.parameters',
        path: '/parameters'
      },
      {
        name: 'Swagger',
        id: 'menu.documentation.swagger',
        url: `${PUBLIC_API_URL}/swagger/index.html`
      },
      {
        name: 'Swagger Extended',
        id: 'menu.documentation.swagger-extended',
        hidden: !permissions[Permissions.extendedApi],
        url: `${PUBLIC_API_URL}/swagger/extended/index.html`
      },
      {
        name: 'Swagger Misc',
        id: 'menu.documentation.swagger-misc',
        hidden: !permissions[Permissions.miscellaneousApi],
        url: `${PUBLIC_API_URL}/swagger/misc/index.html`
      },
      {
        name: 'Updates',
        id: 'menu.documentation.updates',
        path: '/updates'
      },
    ].filter(i => !i.hidden),
  },
  {
    name: 'Apps',
    id: 'menu.apps',
    align: 'left',
    path: '/apps'
  },
  {
    name: 'Profile',
    id: 'menu.headers.profile',
    align: 'right',
    useIcon: true,
    icon: profileIcon,
    children: profileMenu,
  }
]);

const Logo = () => (
  <div className="logo">
    <Link to={'/'}>
      <img src={myUplinkLogo} alt="myUplinkDevs"/>
    </Link>
  </div>);

class TopMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    appPermissions: PropTypes.object,

    logoutUser: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  _onLogOut = () => {
    this.props.logoutUser();
    this.props.push('/login');
  };

  render() {
    const menuItems = MenuLayout({ onLogOut: this._onLogOut, permissions: this.props.appPermissions || {} });

    return (<header className="header-container">
      <div className="header-container-content">
        <Logo />
        <DesktopMenu menuItems={menuItems} />
        <div className="mobileMenu">
          <HamburgerMenu menuItems={menuItems} />
        </div>
      </div>
    </header>);
  }
}

export default connect(
  ({
    app: { userInfo, appPermissions },
  }) => ({
    userInfo,
    appPermissions,
  }),
  { logoutUser, push }
)(TopMenu);
