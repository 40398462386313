import React from 'react';
import PropTypes from 'prop-types';
import EditEmailForm from './EditEmailForm';

const EmailTab = (props) => {
  return (
    <div className="emailTab">
      <EditEmailForm
        onCancel={props.onEditingCancel}
        onSubmit={props.onEmailSubmit}
        values={props.values}
        errors={props.errors}
        touched={props.touched}
        handleChange={props.handleChange}
        handleBlur={props.handleBlur}
        isPendingNewEmail={props.isPendingNewEmail} />
    </div>
  )
}

EmailTab.propTypes = {
  onEmailSubmit: PropTypes.func,
  onEditingCancel: PropTypes.func,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isPendingNewEmail: PropTypes.bool.isRequired,
}
export default EmailTab;