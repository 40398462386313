import React from 'react';
import { FormattedMessage } from 'react-intl';
import CreateYourOwnApp from '../../../../../../assets/img/myuplink_login.png';
import Content from '../../../Content/Content';
import './slide.scss';

const Slide = () => {

  return (
    <div className="slide">
    <div className="slide-inner">
    <div className="slide-content">
      <Content>
        <h3><FormattedMessage id={'login.headerText'} defaultMessage="Make it your own" /></h3>
        <p className="slide-text">
          <FormattedMessage id={'login.imageText'} defaultMessage="Create your own app with myUplink API" />
        </p>
        </Content>
        <div className="image">
        <img alt="Create your own app with myUplink Public API" src={CreateYourOwnApp} />
      </div>
      </div>
      </div>
    </div>
  )
};


export default Slide;
