import { Modal } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../components/Button';

const DeleteAppModal = ({ id, name, isShow, deleteApp, onHide }) => {
  return (
    <Modal
      bsSize="md"
      show={isShow}
      backdrop={true}
      onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="modal.apps.warning" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedHTMLMessage id="modal.apps.warning.message" />
        <b>{name}</b>?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button className="btn btn-primary" type="button" onClick={onHide}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button className="btn btn-danger" type="button" onClick={
          async () => {
            await deleteApp(id);
          }
        }>
          <FormattedMessage id="button.remove" />
        </Button>
      </Modal.Footer>
    </Modal>);
};

DeleteAppModal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isShow: PropTypes.bool,
  deleteApp: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default DeleteAppModal;
