import {
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_RESPONSE_SUCCEDED,
    CHANGE_EMAIL_RESPONSE_SUCCEDED,
    RESET_EMAIL_FORM_ERROR_REQUEST,
    DELETE_ACCOUNT_FAILED,
    DELETE_ACCOUNT_REQUEST,
    RESET_DELETE_ERROR,
    VALIDATE_CREDENTIALS_REQUEST,
    VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED,
    VALIDATE_CREDENTIALS_RESPONSE_FAILED,
  } from './actions';
  
  const initialState = {
    passwordError: null,
    profileError: null,
    deleteError: null,
    validatePasswordError: null,
    emailError: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_PASSWORD_FAILED:
        return { ...state, passwordError: action.error };
      case CHANGE_PASSWORD_RESPONSE_SUCCEDED:
        return { ...state, passwordError: null };
      case CHANGE_EMAIL_RESPONSE_SUCCEDED:
        return { ...state, emailError: null };
      case RESET_EMAIL_FORM_ERROR_REQUEST:
        return { ...state, emailError: null };
      case DELETE_ACCOUNT_REQUEST:
        return { ...state, deleteError: null };
      case RESET_DELETE_ERROR:
        return { ...state, deleteError: null };
      case DELETE_ACCOUNT_FAILED:
        return { ...state, deleteError: action.error };
      case VALIDATE_CREDENTIALS_REQUEST:
        return { ...state, validatePasswordError: null };
      case VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED:
        return { ...state, validatePasswordError: null };
      case VALIDATE_CREDENTIALS_RESPONSE_FAILED:
        return { ...state, validatePasswordError: action.error };
      default:
        return state;
    }
  };