import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeleteAccountForm from './DeleteAccountForm';
import RemoveConfirmation from '../RemoveConfirmation'

class DeleteAccountTab extends React.Component {
  state = {
    showRemovePopup: false,
    isSubmitting: false,
    password: ''
  }

  onRemoveConfirm = async () => {
    this.props.resetDeleteError();
    this.setState({ ...this.state, isSubmitting: true });
    this.props.changeRedirectStatus(false);

    if (await this.props.deleteUser(this.props.userId, this.state.password)) {
    }
    this.setState({ ...this.state, isSubmitting: false, showRemovePopup: false });
  }

  onRemoveAbort = () => {
    this.setState({ ...this.state, showRemovePopup: false });
  }

  onRemoveButtonClick = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const { userEmail, validateUserCredentials } = this.props;
    const password = values.password.trim();
    if (await validateUserCredentials(userEmail, password)) {
      this.setState({ ...this.state, showRemovePopup: true });
      this.setState({ ...this.state, password });
    }

    setSubmitting(false);
  }

  render() {
    const { deleteError, validatePasswordError } = this.props;
    return (
      <div>
        <div className="remove-user-account">
          <FormattedMessage id="label.system-profile.delete-account" defaultMessage="Remove Your Account" />
        </div>
        <DeleteAccountForm onSubmit={this.onRemoveButtonClick} requestError={validatePasswordError || deleteError} />
        {this.state.showRemovePopup && <RemoveConfirmation
          isShown={this.state.showRemovePopup}
          onConfirm={this.onRemoveConfirm}
          onDiscard={this.onRemoveAbort}
          requestError={deleteError}
          isSubmitting={this.state.isSubmitting} />}
      </div>);
  }
}

DeleteAccountTab.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  deleteError: PropTypes.any,
  validateUserCredentials: PropTypes.func.isRequired,
  validatePasswordError: PropTypes.any,
  changeRedirectStatus: PropTypes.func.isRequired
}

export default DeleteAccountTab;