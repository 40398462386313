import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AuthenticationTab from './components/AuthenticationTab/AuthenticationTab';
import ClientAuthTab from './components/ClientAuthTab/ClientAuthTab';
import ClientRegistrationTab from './components/ClientRegistrationTab/ClientRegistrationTab';
import 'react-tabs/style/react-tabs.css';
import './auth.scss';

class Auth extends Component {
  state = {
    selectedTab: 'auth'
  }

  selectTab = (tab) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.setState({ ...this.state, selectedTab: tab });
  }

  getCurrentTab = () => {
    switch (this.state.selectedTab) {
      case 'auth':
        return <AuthenticationTab />
      case 'client-registration':
        return <ClientRegistrationTab />
      case 'client-auth':
        return <ClientAuthTab />  
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="auth">
        <div className="row">
          <div className="tabordion" tabIndex="-1">
            <section id="auth-section" tabIndex="0">
              <div onClick={() => this.selectTab('auth')} tabIndex="0" className={this.state.selectedTab === 'auth' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.auth'} defaultMessage="Authentication" />
              </div>
              <div onClick={() => this.selectTab('client-registration')} tabIndex="0" className={this.state.selectedTab === 'client-registration' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.client-registration'} defaultMessage="Client Registration" />
              </div>
              <div onClick={() => this.selectTab('client-auth')} tabIndex="0" className={this.state.selectedTab === 'client-auth' ? 'option-label selected' : 'option-label'}>
                <FormattedMessage id={'label.client-auth'} defaultMessage="Client Authentication / Authorization" />
              </div>
            </section>
            <div className="tab-content" tabIndex="0">
              {this.getCurrentTab()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
