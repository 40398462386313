import React from "react";
import PropTypes from "prop-types";
import Router from './Routes';

import AppInitializer from './AppInitializer';

class App extends React.Component {
  render() {
    return (
      <AppInitializer>
        <Router />
      </AppInitializer>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default App;
