import {deleteAuthTokens, putAuthTokens} from "../../localStorage";

import {
  AUTH,
  AUTH_FAIL,
  AUTH_SUCCESS,

  VALIDATE_CREDENTIALS,
  VALIDATE_CREDENTIALS_FAIL,
  VALIDATE_CREDENTIALS_SUCCESS,

  LOGOUT_USER,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  acceptedAgreements: null,
  modalError: null
};

const actionHandlers = {
  [VALIDATE_CREDENTIALS](state) {
    return { ...state, loading: true, error: null }
  },
  [VALIDATE_CREDENTIALS_SUCCESS](state, action) {
    return { ...state, loading: false, acceptedAgreements: action.payload.data, modalError: null, error: null }
  },
  [VALIDATE_CREDENTIALS_FAIL](state, action) {
    return { ...state, loading: false, error: action.error }
  },

  [AUTH](state) {
    return { ...state, loading: true };
  },
  [AUTH_SUCCESS](state, action) {
    putAuthTokens(action.payload.data);
    return {...state, loading: false, modalError: null };
  },
  [AUTH_FAIL](state, action) {
    return { ...state, loading: false, error: action.error.description };
  },

  [LOGOUT_USER](state) {
    deleteAuthTokens();
    return { ...state, error: '' };
  },
};

export default function reducer(state = initialState, action){
  const { type } = action;
  const actionHandler = actionHandlers[type];
  if(actionHandler) {
    return actionHandler(state, action);
  }

  return state;
}
