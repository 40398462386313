import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
class ClientAuthTab extends React.Component {

  render() {
    return (
    <div className="col-lg-12">
    <p><FormattedHTMLMessage id="wiki.auth.step3" defaultMessage="" /></p>
    </div>
);
  }
}

export default ClientAuthTab;