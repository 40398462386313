import React from 'react';
import PropTypes from 'prop-types';
import './base-legal-page.scss';

class BaseLegalPage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="legal-container">
        {this.props.content}
      </div>
    );
  }
}

export default BaseLegalPage;