import React from 'react';
import PropTypes from 'prop-types';

import './text-area.scss';

class TextArea extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    rows: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    children: PropTypes.any,
    readOnly: PropTypes.any,
  };

  static defaultProps = {
    onChange() { },
  };

  state = {
    value: 'value' in this.props && this.props.value ? this.props.value : '',
    oldPropsValue: 'value' in this.props && this.props.value ? this.props.value : '',
    focused: false,
    error: null,
  };

  static getDerivedStateFromProps(nextProps, state) {
    return nextProps.value !== state.value && nextProps.value !== state.oldPropsValue
      ? {
        value: nextProps.value,
        oldPropsValue: nextProps.value,
      }
      : null;
  }

  handleFocusChange = (e) => {
    this.setState({
      focused: e.type === "focus"
    });

    if (this.props.validator) {
      this.setState({ ...this.state, error: this.props.validator(this.state.value) });
    }

    if (this.props.onBlur && !this.props.error && this.props.validator && !this.props.validator(this.state.value)) {
      this.props.onBlur(this.state.value);
    }
  };

  handleAutoFill = (e) => {
    this.setState({
      focused: e.animationName === "onAutoFillStart"
    });
  };

  handleChange = (e) => {
    const { props } = this;
    const val = e.target.value;

    this.setState({
      value: val,
    });

    props.onChange({
      target: {
        ...props,
        type: e.target.type,
        value: val,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  };

  render() {
    const { value, focused } = this.state;
    const {
      id,
      name,
      placeholder,
      label,
      error,
      readOnly,
      rows,
    } = this.props;

    const showErrorMessage = error || this.state.error ? true : false;

    if (readOnly === true) {
      return (
        <div className={"inputComponent" + (showErrorMessage ? " inputComponent--has-error" : "")}>
          {label ? (<label className="inputComponent__label" htmlFor={id}>{label}</label>) : ''}

          {showErrorMessage && (<p className="inputComponent__error">{error || this.state.error}</p>)}

          <label htmlFor={this.props.id} className="inputComponent__placeholder__label">
            <span className={"inputComponent__placeholder" + (((value && value.length) || focused) ? " focused" : "")}>
              {placeholder}
            </span>
            <textarea className={"inputComponent__field" + (showErrorMessage ? " inputComponent__field--error" : "")}
                      id={id}
                      rows={rows}
                      name={name}
                      value={value}
                      onBlur={this.handleFocusChange}
                      onChange={e => this.handleChange(e)}
                      onFocus={this.handleFocusChange}
                      onAnimationStart={this.handleAutoFill}
                      autoComplete='new-password'
                      readOnly
            />
          </label>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className={"inputComponent" + (showErrorMessage ? " inputComponent--has-error" : "")}>
          {label ? (<label className="inputComponent__label" htmlFor={id}>{label}</label>) : ''}

          {showErrorMessage && (<p className="inputComponent__error">{error || this.state.error}</p>)}

          <label htmlFor={this.props.id} className="inputComponent__placeholder__label">
            <span className={"inputComponent__placeholder" + (((value && value.length) || focused) ? " focused" : "")}>
              {placeholder}
            </span>
            <textarea className={"inputComponent__field" + (showErrorMessage ? " inputComponent__field--error" : "")}
                      id={id}
                      rows={rows}
                      name={name}
                      value={value}
                      onBlur={this.handleFocusChange}
                      onChange={e => this.handleChange(e)}
                      onFocus={this.handleFocusChange}
                      onAnimationStart={this.handleAutoFill}
                      autoComplete='new-password'
            />
          </label>
          {this.props.children}
        </div>
      );
    }
  }
}

export default TextArea;
