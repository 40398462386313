import {
  USER_AUTH_STATUS_CHANGED,
  GET_USER_INFO_RESPONSE,
  SET_APP_LOADED_STATUS,
  GET_COUNTRIES_RESPONSE,
} from './actions';
import { AUTH_SUCCESS, LOGOUT_USER } from '../../pages/Login/actions';
import { LOAD_APPS_SUCCESS } from '../../pages/Apps/reducers';


const initialState = {
  userLoggedIn: false,
  userInfo: null,
  countries: [],
  url: {},
  appLoaded: false,
  appPermissions: {},
};

export const appGroups = {
  NIBE: 'NIBE',
  NIBE_CRM: 'NIBE_CRM',
  NIBE_SSG: 'NIBE_SSG',
  PARTNER: 'PARTNER',
};

const appPermissions = {
  publicAPI: () => true,
  extendedApi: groups => groups.some(g => g === appGroups.PARTNER),
  miscellaneousApi: groups => groups.some(g =>
    g === appGroups.NIBE || g === appGroups.NIBE_CRM || g === appGroups.NIBE_SSG),
};

export const Permissions = {
  publicAPI: 'publicAPI',
  extendedApi: 'extendedApi',
  miscellaneousApi: 'miscellaneousApi',
};

export const parseAppPermission = apps => {
  const availableGroups = Array.from(new Set(apps.reduce((acc, a) => (a.groups ? [...acc, ...a.groups.map(g => g.name)] : []), [])));
  return Object.keys(appPermissions).reduce((acc, p) => ({ ...acc, [p]: appPermissions[p](availableGroups) }), { publicAPI: true });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_RESPONSE:
      return { ...state, userInfo: action.userInfo };
    case USER_AUTH_STATUS_CHANGED:
      return { ...state, userLoggedIn: action.loggedIn, url: action.url, };
    case AUTH_SUCCESS:
      return { ...state, userLoggedIn: true, };
    case LOGOUT_USER:
      return { ...state, userInfo: null, userLoggedIn: false, };
    case SET_APP_LOADED_STATUS:
      return { ...state, appLoaded: action.status };
    case LOAD_APPS_SUCCESS:
      return { ...state, appPermissions: parseAppPermission(action.payload.data) };
    case GET_COUNTRIES_RESPONSE:
      return { ...state, countries: action.countries, };  
    default:
      return state;
  }
};
