import React from 'react';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import '../../Footer/components/footer-menu.scss';

const FooterMenuItem = ({ id, name, children, align, path, action }) => {
  const alignClass = (align === 'right') ? ' footerMenu__item--right' : '';
  return (
    <li id={id} className={"footerMenu__item" + alignClass} onClick={action}>
        <a className="url" href={path}>
          <FormattedMessage id={id} defaultMessage={name} />
          {children && <span className="caret"></span>}
        </a>
    </li>
  );
};

FooterMenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.array,
  align: PropTypes.string,
  path: PropTypes.string,
  action: PropTypes.func
};

const FooterMenu = ({ menuItems }) => {
  return (
    <nav className="footerMenu">
      <ul className="footerMenu__list">
      <li className="footerMenu__item">
        <span className="footerMenu__item-heading"><FormattedMessage id={'footer.aboutApp'} defaultMessage="About App" /></span>
      </li>
        {menuItems.map((val, index) => <FooterMenuItem key={index} {...val} />)}
      </ul>
      <ul className="footerMenu__copyright">
      <li className="footerMenu-copyright">
          <span>Copyright © {moment().year()} myUpTech AB</span>
        </li>
      </ul>
    </nav>
  );
};

FooterMenu.propTypes = {
  menuItems: PropTypes.array
};

export default FooterMenu;
