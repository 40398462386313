import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatErrorMessage } from '../../../localization/message-formatting'

const errorMessages = {
  'unknown': {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.'
  }
}

const RemoveConfirmation = ({ isShown, onDiscard, onConfirm, requestError, isSubmiting, intl }) => {
  return(
    <Modal show={isShown} backdrop="static" onHide={onDiscard}>
      <div className="remove-confirmation-popup">
        <h2>
          <FormattedMessage id="remove.confirmation.title" defaultMessage="Are you sure you want to permanently remove your account?" />
        </h2>
        <h4>
          {requestError
            && <p className="text-danger">{
              formatErrorMessage(intl, errorMessages, requestError)}
            </p>}
        </h4>
        <div className="popup-bottom-panel">
          <Button type="button" onClick={onDiscard} className="button--default" disabled={isSubmiting}>
            <FormattedMessage id="button.abort" defaultMessage="Abort" />
          </Button>
          <Button type="button" onClick={onConfirm} className="button--secondary" disabled={isSubmiting}>
            <FormattedMessage id="button.ok" defaultMessage="Ok" />
          </Button>
        </div>
      </div>
    </Modal>);
}

RemoveConfirmation.propTypes = {
  isShown: PropTypes.bool,
  onDiscard: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool,
  requestError: PropTypes.any,
  intl: PropTypes.object
};

export default injectIntl(RemoveConfirmation);