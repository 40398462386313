import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Spinner from '../../components/Spinner';
import DeleteAppModal from './components/DeleteAppModal';

import CreateNewApp from './components/CreateNewAppForm';
import AppList from './components/AppList';
import Fade from '../../components/Fade';
import LegalInfoModal from './components/LegalInfoModal/LegalInfoModal';

import { deleteApp, getApps, updateApp, addSecret, expireSecret } from './reducers';

import 'react-tabs/style/react-tabs.css';
import './apps.scss';

class ForDeveloperPage extends React.Component {
  state = {
    selectedTab: 0,
    loading: false,
    showLegalInfoModal: false,
    deleteApp: {},
    showDeleteAppModal: false,
    isValueSaved: false,
  };

  async componentDidMount() {
    const { loadClients } = this.props;

    this.setState({ loading: true });
    await loadClients();
    this.setState({ loading: false });
  }

  _toggleLegalModal = () => {
    this.setState({
      showLegalInfoModal: !this.state.showLegalInfoModal,
    })
  };

  _toggleDeleteModal = (app) => {
    this.setState({
      showDeleteAppModal: !this.state.showDeleteAppModal,
      deleteApp: app
    })
  };

  _onAppAdded = () => {
    this.setState({ selectedTab: 0 });
  };

  _goToCreateApp = () => {
    this.setState({ selectedTab: 1 });
  };

  _deleteApp = async appId => {
    const { loadClients, deleteClient } = this.props;

    this.setState({ loading: true });

    await deleteClient(appId);
    await loadClients();

    this.setState({ loading: false });
    this._toggleDeleteModal({});
  };

  _updateApp = async (id, appName, appDescription, appCallbackUrl) => {
    const { loadClients, updateClient } = this.props;

    this.setState({ loading: true });

    await updateClient(id, appName, appDescription, appCallbackUrl);
    await loadClients();

    this.setState({ loading: false, isValueSaved: true });

    setTimeout(() => {
      this.setState({ isValueSaved: false });
    }, 1000)
  };

  _getSortedApps = (apps) => {
    return [].slice.call(apps).sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    const {
      loading,
      deleteApp,
      selectedTab,
      showDeleteAppModal,
      showLegalInfoModal,
    } = this.state;
    const { apps, addClientSecret, expireClientSecret } = this.props;

    return (
      <div className="fordeveloper">
        <div className="fordeveloper__wrapper row">
        </div>
        <div className="row">
          <div className="">
            <Tabs className="tabordion" selectedIndex={selectedTab} onSelect={index => this.setState({ selectedTab: index })}>
              <TabList>
                <Tab className={this.state.selectedTab === 0 ? 'option-label selected' : 'option-label'}>Applications List</Tab>
                <Tab className={this.state.selectedTab === 1 ? 'option-label selected' : 'option-label'}>Create New Application</Tab>
              </TabList>

              <TabPanel id="tab1">
                {loading && <Fade><Spinner /></Fade>}
                {apps && <AppList
                  apps={this._getSortedApps(apps)}
                  goToCreateApp={this._goToCreateApp}
                  toggleDeleteModal={this._toggleDeleteModal}
                  updateClient={this._updateApp}
                  isValueSaved={this.state.isValueSaved}
                  addClientSecret={addClientSecret}
                  expireClientSecret={expireClientSecret}
                />}
              </TabPanel>
              <TabPanel id="tab2">
                <CreateNewApp
                  onAppAdded={this._onAppAdded}
                  toggleLegalModal={this._toggleLegalModal} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {showLegalInfoModal &&
          <LegalInfoModal
            isShow={this.state.showLegalInfoModal}
            onHide={() => this.setState({ showLegalInfoModal: !showLegalInfoModal })
            } />}
        {showDeleteAppModal &&
          <DeleteAppModal
            id={deleteApp.id}
            name={deleteApp.name}
            isShow={showDeleteAppModal}
            deleteApp={this._deleteApp}
            onHide={this.onDeleteModalHide} />}
      </div>
    );
  }

  onDeleteModalHide = () => this.setState({ showDeleteAppModal: !this.state.showDeleteAppModal });


  static propTypes = {
    userInfo: PropTypes.object,
    loadClients: PropTypes.func.isRequired,
    deleteClient: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    addClientSecret: PropTypes.func.isRequired,
    expireClientSecret: PropTypes.func.isRequired,
    apps: PropTypes.arrayOf(PropTypes.shape({
      sort: PropTypes.func.isRequired
    })).isRequired,
  };
}

export default connect(
  ({ forDeveloper, app }) => ({
    apps: forDeveloper.apps,
    userInfo: app.userInfo,
  }),
  {
    loadClients: getApps,
    deleteClient: deleteApp,
    updateClient: updateApp,
    addClientSecret: addSecret,
    expireClientSecret: expireSecret
  })(ForDeveloperPage);
