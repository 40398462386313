export const LANGUAGE_SELECTED = "language/LANGUAGE_SELECTED";

const initialState = {
  selectedLanguage: 'en-US'
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_SELECTED:
      return { ...state, selectedLanguage: action.selectedLanguage };
    default:
      return state;
  }
}
