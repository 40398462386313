import React from 'react';
import PropTypes from 'prop-types';
import AppListItem from './AppListItem';

const AppList = ({
  apps,
  goToCreateApp,
  toggleDeleteModal,
  updateClient,
  isValueSaved,
  addClientSecret,
  expireClientSecret,
}) =>
  apps && apps.length ? (
    apps.map((app) => (
      <AppListItem
        key={app.id}
        app={app}
        toggleDeleteModal={toggleDeleteModal}
        updateClient={updateClient}
        isValueSaved={isValueSaved}
        addClientSecret={addClientSecret}
        expireClientSecret={expireClientSecret}
      />
    ))
  ) : (
    <>
      <p>No applications here.</p>
      <p>
        Click{' '}
        <a onClick={goToCreateApp} href="#">
          here
        </a>{' '}
        to create a new one.
      </p>
    </>
  );

AppList.propTypes = {
  apps: PropTypes.arrayOf({
    appId: PropTypes.string,
  }).isRequired,
  removeApp: PropTypes.func.isRequired,
  goToCreateApp: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  isValueSaved: PropTypes.bool,
  addClientSecret: PropTypes.func.isRequired,
  expireClientSecret: PropTypes.func.isRequired,
};

export default AppList;
