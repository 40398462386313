import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { formatErrorMessage } from '../../../../localization/message-formatting';

const schema = yup.object().shape({
  password: yup.string().required('password.required')
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field'
  },
  'password.incorrect': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.'
  },
}

const DeleteAccountForm = ({ onSubmit, requestError, intl }) => {
  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className="form--half">
          <Input
            placeholder={intl.formatMessage({ id: ('label.password') })}
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password
              && touched.password
              && formatErrorMessage(intl, errorMessages, errors.password)} />
          {requestError
            && <p className="text-danger">{
              formatErrorMessage(intl, errorMessages, requestError)}
            </p>}
          <div className="button-wrapper">
            <Button className="button--danger" type="submit">
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Button>
          </div>
        </form>)}
    </Formik>
  )
}
DeleteAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(DeleteAccountForm);