import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik/dist/index';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';

import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';
import Spinner from '../../../../components/Spinner';
import Checkbox from '../../../../components/Checkbox';
import { callbackURLRegex } from '../../../../components/constants/constants';

import { addApp, getApps } from '../../reducers';

import './create-new-app-form.scss';

const schema = yup.object().shape({
  appName: yup.string()
    .trim()
    .min(4, 'App Name cannot be less than 4 characters')
    .max(50, 'App Name cannot be longer than 50 characters')
    .required('App Name is mandatory field'),
  description: yup.string()
    .trim()
    .min(4, 'App Description cannot be less than 4 characters')
    .max(80, 'App Description cannot be longer than 80 characters')
    .required('App Description is mandatory field'),
  callbackUrl: yup.string()
    .matches(callbackURLRegex, 'Callback Url must be a valid URL'),
  acceptedAgreement: yup.boolean()
    .required('Must Accept Privacy Policy')
    .oneOf([true], 'Must Accept Privacy Policy'),
});

const CreateNewAppForm = ({ onSubmit, toggleLegalModal }) => (
  <Formik
    validationSchema={schema}
    onSubmit={onSubmit}
  >
    {({
      values,
      errors,
      handleChange,
      handleSubmit,
      isSubmitting,
    }) => (
        <>
          <div className="row">
            <div className="col-lg-12">
              {(isSubmitting) && <Spinner dark />}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="createNewAppForm">
            {(!isSubmitting) && <div>
              <div className="formInput formInput--half formInput--border">
                <FormattedMessage id='apps.appName'>
                  {placeholder =>
                    <Input
                      name="appName"
                      id="appName"
                      type="text"
                      value={values.appName || ''}
                      placeholder={placeholder}
                      onChange={handleChange}
                      error={errors.appName ? errors.appName : ""}
                    />}
                </FormattedMessage>
              </div>
              <div className="formInput formInput--half formInput--border">
                <FormattedMessage id='apps.description'>
                  {placeholder =>
                    <TextArea
                      placeholder={placeholder}
                      name="description"
                      id="description"
                      value={values.description || ''}
                      rows={10} style={{ width: '100%' }}
                      error={errors.description ? errors.description : ""}
                      onChange={handleChange}
                    />}
                </FormattedMessage>
              </div>
              <div className="formInput formInput--half formInput--border">
                <FormattedMessage id='apps.callbackUrl'>
                  {placeholder =>
                  <Input
                    placeholder={placeholder}
                    name="callbackUrl"
                    id="callbackUrl"
                    value={values.callbackUrl || ''}
                    rows={10} style={{ width: '100%' }}
                    error={errors.callbackUrl ? errors.callbackUrl : ""}
                    onChange={handleChange}
                  />}
                </FormattedMessage>
              </div>
              <div className="clearfix"></div>
              <FormattedMessage id='apps.user-terms' defaultMessage='I accept the Terms of Service'>
                {title => {
                  return (
                    <div className="formInput formInput__checkbox">
                      <Checkbox
                        id="acceptedAgreement"
                        name="acceptedAgreement"
                        thin={true}
                        checked={values.acceptedAgreement}
                        error={errors.acceptedAgreement ? errors.acceptedAgreement : ""}
                        onChange={e => { handleChange(e) }} />
                      <div className="checkboxLabel">
                        <span className="legalTextButton"
                          onClick={toggleLegalModal}>{title}</span>
                      </div>
                    </div>)
                }
                }
              </FormattedMessage>
              <div className="clearfix"></div>
              <div className="formInput formInput--half">
                <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                  <FormattedMessage id="apps.create"
                    defaultMessage="Create" />
                </button>
              </div>
              <div className="clearfix"></div>
            </div>}
          </form>
        </>
      )}
  </Formik>
);

CreateNewAppForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleLegalModal: PropTypes.func.isRequired,
};

class CreateNewApp extends React.Component {

  static propTypes = {
    addClient: PropTypes.func.isRequired,
    loadClients: PropTypes.func.isRequired,
    onAppAdded: PropTypes.func.isRequired,
    toggleLegalModal: PropTypes.func.isRequired,
  };

  onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { addClient, loadClients, onAppAdded } = this.props;
    const { appName, description, callbackUrl } = values;

    setSubmitting(true);

    await addClient(appName, description, callbackUrl);
    await loadClients();

    resetForm({});
    setSubmitting(false);
    onAppAdded();
  };

  render() {
    const { toggleLegalModal } = this.props;

    return (
      <div className="contact step-1">
        <div className="contact__wrapper row">
          <div className="col-lg-12">
            <p id="apps"><FormattedMessage id="apps.createapp" defaultMessage="Apps" /></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <CreateNewAppForm
              onSubmit={this.onSubmit}
              toggleLegalModal={toggleLegalModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    addClient: addApp,
    loadClients: getApps,
  }
)(CreateNewApp);
