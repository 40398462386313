import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

class LanguageTab extends React.Component {

  render() {
    return (
      <p><FormattedHTMLMessage id="wiki.language" defaultMessage="" /></p>
    );
  }
}

export default LanguageTab;