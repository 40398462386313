import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatErrorMessage } from '../../../localization/message-formatting'

const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required('old-password.required'),
  newPassword: yup.string()
    .oneOf([yup.ref('newPasswordRepeated'), null], 'password.match')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, 'password.regexp')
    .test('consecutiveSymbols', 'password.consecutive', v => !(/(.)\1\1/.test(v)))
    .max(128, 'password.maxlength')
    .required('password.required'),
  newPasswordRepeated: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'password.match')
    .required('password.confirm.required')
});

const errorMessages = {
  'old-password.required': {
    id: 'profile.change-password.error.validation.old-password.required',
    defaultMessage: 'Old password is mandatory field'
  },
  'old-password.invalid': {
    id: 'profile.change-password.error.request.old-password.invalid',
    defaultMessage: 'Your old password appears to be incorrect'
  },
  'password.match': {
    id: 'password.error.validation.match',
    defaultMessage: 'Passwords must match'
  },
  'password.regexp': {
    id: 'password.error.validation.regexp',
    defaultMessage: 'Password must be at least 8 characters long with uppercase letters, numbers and symbols'
  },
  'password.consecutive': {
    id: 'password.error.validation.consecutive',
    defaultMessage: 'Password cannot contain more than 2 identical characters'
  },
  'password.maxlength': {
    id: 'password.error.validation.maxlength',
    defaultMessage: 'Password must be no longer than 128 characters'
  },
  'password.required': {
    id: 'profile.change-password.error.validation.new-password.required',
    defaultMessage: 'Password is mandatory field'
  },
  'password.confirm.required': {
    id: 'profile.change-password.error.validation.password-confirm.required',
    defaultMessage: 'New password confirm is mandatory field'
  }
}

const ChangePasswordForm = ({ isChanged, onSubmit, requestError, intl }) => {
  const initialValues = { oldPassword: '', newPassword: '', newPasswordRepeated: '' };

  return (<Formik
    initialValues={initialValues}
    validationSchema={passwordSchema}
    onSubmit={onSubmit}>
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    }) => (
        <div>
          {isChanged && <div className="success-label"><FormattedMessage id="change-password.success" defaultMessage="Password has been changed!" /></div>}
          <form onSubmit={handleSubmit} className="form--half">
            <FormattedMessage id="label.currentPassword" defaultMessage="Current Password">
              {placeholder =>
                <Input
                  placeholder={placeholder}
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="oldPassword"
                  error={errors.oldPassword
                      && touched.oldPassword
                      && formatErrorMessage(intl, errorMessages, errors.oldPassword)} />}
            </FormattedMessage>
            <FormattedMessage id={'label.newPassword'} defaultMessage={'New Password'}>
              {placeholder =>
                <Input 
                  placeholder={placeholder}
                  type="password"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.newPassword
                      && touched.newPassword
                      && formatErrorMessage(intl, errorMessages, errors.newPassword)} />}
            </FormattedMessage>
            <FormattedMessage id={'label.newPasswordRepeated'} defaultMessage={'New Password Repeated'}>
              {placeholder =>
                <Input
                  placeholder={placeholder}
                  value={values.newPasswordRepeated}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="newPasswordRepeated"
                  error={errors.newPasswordRepeated
                    && touched.newPasswordRepeated
                    && formatErrorMessage(intl, errorMessages, errors.newPasswordRepeated)} />}
            </FormattedMessage>
            {requestError
              && <p className="text-danger">{
                formatErrorMessage(intl, errorMessages, requestError)}
              </p>}
            <div className="button-wrapper">
              <Button className="button--secondary" type="submit" disabled={isSubmitting}>
                <FormattedMessage id="button.change-password" defaultMessage="Change password" />
              </Button>
            </div>
          </form>
        </div>)}
  </Formik>);
}

ChangePasswordForm.propTypes = {
  isChanged: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(ChangePasswordForm);