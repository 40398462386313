import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  initUser,
  getUserInfo,
  setAppLoadedStatus,
  getCountries,
} from './actions';
import { getApps } from '../../pages/Apps/reducers';

class AppInitializer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)]).isRequired,

    initUser: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    setAppLoadedStatus: PropTypes.func.isRequired,
    getApps: PropTypes.func.isRequired,
    getCountries: PropTypes.func,

    userLoggedIn: PropTypes.bool,
    userInfo: PropTypes.object,
    appLoaded: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    userLoggedIn: false,
  };

  async componentDidMount() {
    await this.props.initUser();
    await this.props.getApps();
    await this.props.getCountries();

    await this.props.setAppLoadedStatus(true);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.userLoggedIn && prevProps.userLoggedIn !== this.props.userLoggedIn) {
      await this.props.getUserInfo();
      await this.props.getApps();
    }
  }

  render() {
    if (!this.props.appLoaded) {
      return null;
    }

    return this.props.children;
  }
}

const withConnect = connect(
  (
    { app }
  ) =>
    (
      { ...app }
    ),
  {
    initUser,
    getUserInfo,
    setAppLoadedStatus,
    getCountries,

    getApps,
  });

export default compose(
  withRouter,
  withConnect,
)(AppInitializer);
